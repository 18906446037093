<script setup>
import Tooltip from '@/Components/Tooltip.vue';

const props = defineProps({ 
    inactive: Boolean,
    icon: String, 
    tooltip: String,
    tooltipText: String,
    rounded: String,
});

const emit = defineEmits(['onClick', 'onMousedown', 'onMouseup']);

const onClick = () => {
    emit('onClick', { } );
}
const onMousedown = () => {
    emit('onMousedown', { } );
}
const onMouseup = () => {
    emit('onMouseup', { } );
}

</script>

<template>
    <div class="relative flex">
        <button :title="props.icon" :class="[props.inactive === true ? 'inactive':'active', !props.rounded ? 'rounded-lg':'', props.rounded == 'left' ? 'rounded-l-lg':'', props.rounded == 'right' ? 'rounded-r-lg':'']" 
                class="icon-format peer" 
                @click="onClick()" 
                @touchstart="onMousedown()" 
                @touchend="onMouseup()" 
                @mousedown="onMousedown()" 
                @mouseup="onMouseup()">
            <svg v-if="props.icon == 'expand' || props.icon == 'down'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" > 
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/> 
            </svg>
            <svg v-if="props.icon == 'collaps' || props.icon == 'up'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" > 
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
            <svg v-if="props.icon == 'left'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" stroke-width="1.3"> 
                <path d="m3.86 8.75 5.48 4.8A1 1 0 0 0 11 12.8V3.2a1 1 0 0 0-1.66-.75l-5.48 4.8a1 1 0 0 0 0 1.5z"/>
            </svg>
            <svg v-if="props.icon == 'right'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" stroke-width="1.3"> 
                <path d="m12.14 8.75-5.48 4.8A1 1 0 0 1 5 12.8V3.2a1 1 0 0 1 1.66-.75l5.48 4.8a1 1 0 0 1 0 1.5z"/>
            </svg>
            <svg v-if="props.icon == 'addItem'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 24 24" stroke-width="2"> 
                <path fill-rule="evenodd" d="M11 17a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V7a1 1 0 1 0-2 0v4H7a1 1 0 1 0 0 2h4v4Z" clip-rule="evenodd"/>
            </svg>
            <svg v-if="props.icon == 'deleteItem'" class="w-3.5 h-3.5 fill-none stroke-current" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
            <svg v-if="props.icon == 'menu'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 20 20" stroke-width="1.7" >
                <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
            </svg>
            <svg v-if="props.icon == 'clear'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" stroke-width="1.3" >
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M4.65 4.65a.5.5 0 0 1 .7 0L8 7.29l2.65-2.64a.5.5 0 0 1 .7.7L8.71 8l2.64 2.65a.5.5 0 0 1-.7.7L8 8.71l-2.65 2.64a.5.5 0 0 1-.7-.7L7.29 8 4.65 5.35a.5.5 0 0 1 0-.7z"/>
            </svg>
            <svg v-if="props.icon == 'close'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 22 22" stroke-width="1.8">
                <path fill-rule="nonzero" d="M1.72.29 11 9.57 20.28.3A1 1 0 0 1 21.6.2l.11.1a1 1 0 0 1 0 1.4L12.43 11l9.28 9.28a1 1 0 0 1 .08 1.32l-.1.11a1 1 0 0 1-1.4 0L11 12.43 1.72 21.7a1 1 0 0 1-1.32.08l-.11-.1a1 1 0 0 1 0-1.4L9.57 11 .3 1.72A1 1 0 0 1 .2.4L.3.29a1 1 0 0 1 1.4 0ZM1 1 10.99 11l-10 10h.02L11 11.01l10 10v-.02L11.01 11l10-10h-.02L11 10.99 1 .99v.02Z"/>
            </svg>
            <svg v-if="props.icon == 'info'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" stroke-width="1.3">
                <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z"/>
            </svg>
            <svg v-if="props.icon == 'speak'" class="w-3.5 h-3.5 fill-none stroke-current" viewBox="0 0 256 256" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"> 
                <path d="M218.9 77.1a71.9 71.9 0 0 1 0 101.8M80 168H32a8 8 0 0 1-8-8V96a8 8 0 0 1 8-8h48l72-56v192ZM80 88v80M190.6 105.4a31.9 31.9 0 0 1 0 45.2"/>
            </svg>
            <svg v-if="props.icon == 'mic'" class="w-3.5 h-3.5 fill-none stroke-current" viewBox="0 0 256 256" stroke-width="21" stroke-linecap="round" stroke-linejoin="round">
                <rect x="88" y="24" width="80" height="144" rx="40" ></rect>
                <line x1="128" y1="200" x2="128" y2="232"></line>
                <path d="M199.6,136a72.1,72.1,0,0,1-143.2,0"></path>
            </svg>
            <svg v-if="props.icon == 'link'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 16 16" stroke-width="1.5"> 
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
            </svg>
            <svg v-if="props.icon == 'search'" class="w-3.5 h-3.5 fill-current" viewBox="0 0 512 512" stroke-width="42"> 
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7l126.6 126.7c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
            </svg>
            <svg v-if="props.icon == 'statistic'" class="w-3.5 h-3.5 stroke-current fill-none" viewBox="0 0 24 24" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"> 
                <path stroke-miterlimit="5.759" d="M3 3v16a2 2 0 0 0 2 2h16"/>
                <path stroke-miterlimit="5.759" d="m7 14 4-4 4 4 6-6"/>
                <path stroke-linejoin="round" d="M18 8h3v3"/>
            </svg>
            <svg v-if="props.icon == 'confirm'" class="w-3.5 h-3.5 stroke-current fill-none" viewBox="0 0 512 512" stroke-width="42" stroke-linejoin="round" stroke-linecap="round"> 
                <path d="M336 176 225.2 304 176 255.8"/>
                <path d="M463.1 112.37C373.68 96.33 336.71 84.45 256 48c-80.71 36.45-117.68 48.33-207.1 64.37C32.7 369.13 240.58 457.79 256 464c15.42-6.21 223.3-94.87 207.1-351.63Z"/>            
            </svg>
            <svg v-if="props.icon == 'confirm2'" class="w-3.5 h-3.5 stroke-current fill-none" vviewBox="0 0 16 16" stroke-width="1.5" stroke-linejoin="round" stroke-linecap="round"> 
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>           
            </svg>

            <svg v-if="props.icon == 'edit'" class="w-3.5 h-3.5 stroke-current fill-none" viewBox="0 0 24 24" stroke-width="1.9" stroke-linecap="round" stroke-linejoin="round"  >
                <path d="m14.3 4.8 2.9 2.9M7 7H4a1 1 0 0 0-1 1v10c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-4.5m2.4-10a2 2 0 0 1 0 3l-6.8 6.8L8 14l.7-3.6 6.9-6.8a2 2 0 0 1 2.8 0Z"/>
            </svg>
            <svg v-if="props.icon == 'search2'" class="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500 fill-current" viewBox="0 0 72 55">
                <path d="m58.73 44.35-11-11a21.26 21.26 0 0 1-6.37 6.37l11 11a4.51 4.51 0 0 0 6.38-6.38ZM48 22a18 18 0 1 0-18 18 18 18 0 0 0 18-18ZM30 35.52A13.53 13.53 0 1 1 43.52 22 13.55 13.55 0 0 1 30 35.52Z"/>
                <path d="M19.47 22h3A7.52 7.52 0 0 1 30 14.47v-3A10.53 10.53 0 0 0 19.47 22Z"/>
            </svg>
            <svg v-if="props.icon == 'unlink'" viewBox="0 0 640 512" class="w-3.5 h-3.5 stroke-current fill-none" stroke-width="42">
                <path d="M185.7 120.3c56.8-44.48 139-40.57 190.4 10.8 44 44 54.8 108.5 30.6 162.4l31.9 24.9 95.9-95.9c31.5-31.5 31.5-83.4 0-114-27.8-27.87-71.8-32.4-103.8-8.6l-1.6 1.1c-14.4 10.3-34.4 6.1-44.6-7.42-10.3-14.38-7-34.37 7.4-44.64l1.6-1.12C451 6.732 529.8 13.25 579.8 63.24c56.5 56.46 56.5 148.06 0 204.46l-90.5 90.5 141.5 110.9c10.4 8.2 12.3 23.3 4.1 33.7-8.2 10.4-23.3 12.3-33.7 4.1L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196 13.29-1.236 28.37-3.065 38.81 5.112L185.7 120.3zm52.4 40.8 115.3 90.6c5.9-26.2-1.7-54.5-21.7-74.5-25.1-25.1-62.6-30.2-93.6-16.1zM263 380c-29.9-29.9-44.3-70.2-42.1-110l185.7 146.4C357.4 431 301.9 418.9 263 380zM116.6 187.9l50.6 39.9-61.7 61.7c-31.51 30.6-31.51 82.5 0 114 27.8 27.9 71.8 31.5 103.8 8.6l1.6-2c14.4-9.4 34.4-6.1 44.6 8.3 10.3 14.4 7 34.4-7.4 44.7l-1.6 1.1c-58.4 41.1-136.3 34.5-186.29-15.4-56.469-56.5-56.469-148.1 0-204.5l56.39-56.4z"/>
            </svg>
            <svg v-if="props.icon == 'play'" viewBox="0 0 512 512" class="fill-gray-700 dark:fill-gray-300 w-3.5 h-3.5">
                <g stroke-width="0"></g>
                <g stroke-linecap="round" stroke-linejoin="round"></g>
                <g >
                    <path class="fill-gray-700 dark:fill-gray-300" d="M106.854 106.002a26.003 26.003 0 0 0-25.64 29.326c16 124 16 117.344 0 241.344a26.003 26.003 0 0 0 35.776 27.332l298-124a26.003 26.003 0 0 0 0-48.008l-298-124a26.003 26.003 0 0 0-10.136-1.994z"></path>
                </g>
            </svg>

        </button>
        <Tooltip v-if="props.tooltip" v-show="props.inactive == false" :direction="props.tooltip" :text="props.tooltipText ? $t(props.tooltipText):$t(props.icon)" />
    </div>

</template>
